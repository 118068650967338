import { render, staticRenderFns } from "./boolean_field.vue?vue&type=template&id=0772636a&scoped=true&"
import script from "./boolean_field.vue?vue&type=script&lang=js&"
export * from "./boolean_field.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0772636a",
  null
  
)

export default component.exports